import "../css/main.css";

// General vars
const body = document.body;

// Prevent scrolling and maintain scroll position. Use for open modals.
const noScroll = () => {
  if (body.classList.contains("noScroll")) {
    const scrollY = body.style.top;
    body.classList.remove("noScroll");
    body.style.position = "relative";
    body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  } else {
    body.classList.add("noScroll");
    body.style.position = "fixed";
    body.style.top = `-${window.scrollY}px`;
  }
};

// Mobile navigation ----------------------------------------------------------
const menuButton = document.querySelector("#menu-button");
const menuMobile = document.querySelector("#menu__mobile");
const menuMobile_inner = document.querySelector("#menu__mobile__inner");

const toggleNav = () => {
  menuButton.classList.toggle("active");
  if (menuMobile.classList.contains("hidden")) {
    menuMobile.classList.toggle("hidden");
    menuMobile.classList.toggle("flex");
    // Prevents transition from being skipped
    window.setTimeout(() => {
      menuMobile.classList.toggle("bg-opacity-0");
      menuMobile.classList.toggle("bg-opacity-70");
      menuMobile_inner.classList.toggle("-right-full");
      menuMobile_inner.classList.toggle("right-0");
    }, 10);
  } else {
    menuMobile.classList.toggle("bg-opacity-0");
    menuMobile.classList.toggle("bg-opacity-70");
    menuMobile_inner.classList.toggle("-right-full");
    menuMobile_inner.classList.toggle("right-0");
    // Ensures transition animation finishes before hiding mobile menu
    window.setTimeout(() => {
      menuMobile.classList.toggle("hidden");
      menuMobile.classList.toggle("flex");
    }, 150);
  }
  noScroll();
};

menuButton.addEventListener("click", toggleNav);
menuMobile.addEventListener("click", toggleNav);
menuMobile_inner.addEventListener("click", function (event) {
  event.stopPropagation();
});
// End mobile navigation ------------------------------------------------------

// Mode toggle ----------------------------------------------------------------
const modeButtons = document.querySelectorAll(".mode-button");

modeButtons.forEach((button) => {
  button.addEventListener("click", () => {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    } else {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    }
  });
});
